.ScheduleContainer {
	justify-content: flex-end;
	margin-right: 1rem !important;

	@media (max-width: 991.98px) {
		justify-content: flex-start;
		margin-top: 20px;
	}

	@media (max-width: 767.98px) {
		flex-direction: row;
		align-items: stretch;
		margin-top: 0px;
		margin-right: 0rem !important;
		justify-content: space-evenly;
		div {
			align-self: center;
		}
	}
}

.scheduleDemo {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;

	@media (max-width: 767.98px) {
		margin: 1.5rem 0px;
	}

	p {
		color: #fff;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.2px;
		margin-bottom: 0px;

		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}
}

.orderNow {
	display: flex;
	padding: 20px 32px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 10px;

	@media (max-width: 767.98px) {
		padding: 13px 40px;
	}

	p {
		color: #fff;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.2px;
		margin-bottom: 0px;

		@media (max-width: 767.98px) {
			font-size: 14px;
		}
	}
}

.aboutUs {
	margin: 0rem 0rem;
	padding: 7rem 0rem;
	@media (max-width: 767.98px) {
		padding: 2rem 0rem;
	}
}

.componentLoader {
	margin: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 64px;
	.loader {
		width: 60px;
		aspect-ratio: 2;
		--_g: no-repeat radial-gradient(circle closest-side, #f6a200 -76.29%, #ea3162 89.33%, #0000);
		background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
		background-size: calc(100% / 3) 50%;
		animation: l3 1s infinite linear;
	}
	@keyframes l3 {
		20% {
			background-position: 0% 0%, 50% 50%, 100% 50%;
		}
		40% {
			background-position: 0% 100%, 50% 0%, 100% 50%;
		}
		60% {
			background-position: 0% 50%, 50% 100%, 100% 0%;
		}
		80% {
			background-position: 0% 50%, 50% 50%, 100% 100%;
		}
	}
}

.activeAccordionText {
	box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
}

.activeAccordionHeader {
	font-weight: 500;
}

//---------sticky form-------------

.stickyForm {
	box-sizing: border-box;

	width: -moz-fit-content;
	width: fit-content;
	display: flex;
	justify-content: end;
	align-items: start;
	visibility: visible;
	position: fixed;
	z-index: 11;
	right: 1.5rem;
	bottom: 0px !important;

	.closedForm {
		background: linear-gradient(0deg, var(--bs-yellow) -76.29%, var(--bs-red) 89.33%);
		width: 250px;
		height: 30px;
		border-radius: 10px 10px 0 0;
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		align-items: center;
		:hover {
			cursor: pointer;
		}
		p {
			color: white;
			width: fit-content;
		}
		@media (max-width: 767.98px) {
			font-size: 12px;
			width: 190px;
			height: 28px;
		}
	}

	.openForm {
		display: block;
		background-color: grey;
		min-width: 350px;
		width: fit-content;
		max-height: 90vh;
		position: relative;
		padding: 4px;
		background: rgb(206 202 202);
		z-index: 11;
		border-radius: 5px;
		bottom: 5.5rem;
		label {
			font-weight: 600;
			font-size: 13px;
			display: flex;
			align-items: center;
		}
		img{
			margin: 0px 2px 0px 0px !important;
		}
		// full screen code
		@media (max-width: 767.98px) {
			right: -1.6rem;
			width: 100vw;
			min-width: fit-content;
			border-radius: 0;
		}
		.mainForm {
			height: 90%;
			min-height: fit-content;
			padding: 10px;
			background: white;
			border-radius: 5px;
		}

		.formHeading {
			color: white;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: 6%;
			svg {
				cursor: pointer;
			}
		}
	}

	.spanError{
			color: #c21625bf;
			font-size: .75rem;
			position: absolute;
			bottom: - 0.5rem;
			padding: 0 0.5rem;
			margin: 0 0.15rem;
			background: #ffffff;
	}
	input {
		font-size: 12px;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	::-webkit-input-placeholder {
		font-size: 12px !important;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		font-size: 12px !important;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		font-size: 12px !important;
	}

		label {
		font-weight: 500 !important;
	}
}

.spinnerBootom {
	bottom: 15rem;
}

.stickyFormBackround {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	z-index: 10;
	bottom: 0 !important;
}

.formNavBar {
		color: black !important;
		button:hover {
			background: none !important;
			color: #6c757d !important;
		}
		span{
			font-size: 14px !important;
			font-weight: 600 !important;
		}
		
	.speakInput {
		height: 7%;
	}
	.borderColor {
		border-color: #ea3162 !important;
		color: #ea3162 !important;
	}
	.unSelectedIcon{
		filter: grayscale(1);
		
	}
	
	.selectedTab{
		padding: 0.5rem 1.5rem !important;
	}
}

.selectForm {
	option {
		&:hover {
			background-color: pink !important;
		}
	}
}


///// icon button css ////

 .CloseButton {
		cursor: pointer;
		z-index: 9;
		right: 2rem;
		width: 3rem;
		height: 3rem;
		bottom: 1.5rem;
		position: fixed;
		text-align: center;
		border-radius: 50%;
		display: inline-block;
		background-color: #f17042;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

	svg {
		left: 0;
		right: 0;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
		margin-left: auto;
		margin-right: auto;
		vertical-align: middle;
		color: white;
	}
 }
 
.BuyNowBtn {
	@extend .CloseButton;
	animation-name: tada;
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
	&:before {
		top: 0;
		left: 0;
		z-index: -1;
		content: "";
		width: 100%;
		height: 100%;
		border-radius: 50%;
		position: absolute;
		animation-delay: 0.2s;
		background-color: #f17042;
		animation: ripple 1.5s ease-out infinite;
	}

	@keyframes tada {
		0% {
			transform: scale3d(1, 1, 1);
		}
		10%, 20% {
			transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg);
		}
		30%, 50%, 70%, 90% {
			transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
		}
		40%, 60%, 80% {
			transform: rotate3d(0, 0, 1, -10deg);
		}
		100% {
			transform: scale3d(1, 1, 1);
		}
	}

	@keyframes ripple {
		0% {
			opacity: 1;
			transform: scale(0);
		}
		100% {
			opacity: 0;
			transform: scale(2);
		}

	}
}

 .LoaderBaseWrapper{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
 }

 .LoaderWrapper {
	@extend .LoaderBaseWrapper;
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
	z-index: 1002;
 }

.LoaderBackground {
	@extend .LoaderBaseWrapper;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff8f;
}

